<template>
  <div>
    <b-overlay :show="loading"> 
      <div class="w-100 d-flex justify-content-between">
        <b-button
          variant="warning"
          @click="toBack"
        >
          {{$t('message.back')}}
        </b-button>
      </div>
    

    <ValidationObserver
      ref="refFormObserver"
    >
      <b-card class="mt-2" v-if="!$route.params.id && !$route.params.visaId">
        <!-- Client  -->
        <b-row>
          <b-col md="12">
            <h4>
              {{$t('message.client_text')}}
            </h4>
          </b-col>

          <b-col md="6">
            <ValidationProvider #default="validationContext" :name="$t('data.firstNmae')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.firstNmae')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="person"></b-icon>
                  <input 
                    class="form-control"
                    v-model="clientModel.first_name"
                    @input="toUpperCaseFirstname"
                  />
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Client lastName  -->
          <b-col md="6">
            <ValidationProvider #default="validationContext" :name="$t('data.lastName')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.lastName')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="person"></b-icon>
                  <input
                    class="form-control" 
                    v-model="clientModel.last_name"
                    @input="toUpperCaseLastname"
                  />
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Client birthday  -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.date_birthday')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.date_birthday')}}
                </p>
                <div class="wrapper-form">
                  <!-- <b-icon icon="calendar2-check"></b-icon> -->
                  <input type="date" class="form-control" v-model="clientModel.birthdate">
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Client Passport Data  -->

          <b-col md="4">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.file')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="file-earmark"></b-icon>
                <input type="file" class="form-control" @change="fileChange">
              </div>
              <!-- <b-form-file v-model="visa.client.file" :state="Boolean(visa.client.file)"></b-form-file> -->
            </div>
          </b-col>

          <!-- Client mobile number  -->
          <b-col md="4">
            <ValidationProvider #default="validationContext"  :name="$t('data.mobile_number')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.mobile_number')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="telephone"></b-icon>
                  <b-form-input v-model="number1" type="number" v-mask="'############'"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Client mobile number additional -->
          <b-col md="4">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.mobile_number_additional')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="telephone"></b-icon>
                <b-form-input v-model="number2" type="text" v-mask="'############'"></b-form-input>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="mt-2">
        <b-row>
          <!-- Data country_id -->
          <b-col md="6">
            <ValidationProvider
              #default="validationContext"
              :name="$t('data.where_region')"
              rules="required"
            >
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.where_region')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="arrow-up-right-square"></b-icon>
                  <v-select
                    v-model="dataModel.country_id"
                    :options="countryOptions"
                    label="name_ru"
                    :pushTags="true"
                    taggable
                    @search="searchCountry"
                    @search:blur="selectTempCountry"
                    @option:selected="countrySelected"
                  ></v-select>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider
                #default="validationContext"
                :name="$t('Currency')"
                rules="required"
            >
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('Currency')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <v-select
                      v-model="selectedCurrency"
                      :options="currencies"
                      label="currency"
                  ></v-select>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Data actual_price  -->
          <b-col md="6">
            <ValidationProvider
              #default="validationContext"
              :name="$t('Price')"
              rules="required"
            >
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('Price')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <b-form-input v-model="dataModel.price"  type="number"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Data market_price  -->
          <b-col md="6">
            <ValidationProvider
              #default="validationContext"
              :name="$t('Fee')"
              rules="required"
            >
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('Fee')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <b-form-input v-model="dataModel.fee"  type="number"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              #default="validationContext"
              :name="$t('data.sum_gross_air')"
              rules="required"
            >
            <b-form-group label="Entry date">
              <input type="date" class="form-control" v-model="dataModel.enter_date">
            </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col md="6">
            <ValidationProvider
              #default="validationContext"
              :name="$t('data.sum_gross_air')"
              rules="required"
            >
            <b-form-group label="Release date">
              <input type="date" class="form-control" v-model="dataModel.release_date">
            </b-form-group>
          </ValidationProvider>
          </b-col>
          
        </b-row>
      </b-card>
    </ValidationObserver>

    <div class="w-100 d-flex justify-content-end">
      <b-button
        variant="primary"
        :disabled="disabledItem"
        @click="handleAdd"
      >
        {{$t('message.Add')}}
      </b-button>
    </div>
    </b-overlay>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum } from '@validations';
  import {getClients} from '@/api/clients/clients';
  import {storeVisa, updateVisa, showVisa} from '@/api/visa/index';
  import {getCountries} from '@/api/countries/countries'
  // import { storeVisa } from '@/api/visa'
  import { mapGetters } from 'vuex';
  import moment from 'moment'
  import {getCurrencies} from '@/api/currency/index'
  import vSelect from 'vue-select'
  // import parse from 'uuid/dist/parse';

  export default {
    name: "visa-visaAdd",
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        number1:null,
        number2:null,
        disabledItem: false,
        file: null,
        clientModel: {
          id: null,
          first_name: '',
          last_name: '',
          birthdate: null,
          contacts: [{number: ''}, {number: ''}]
        },
        dataModel: {
          id: null,
          client_id: null,
          country_id: null,
          currency_id: null,
          enter_date: null,
          release_date: null,
          price: null,
          fee: null,
          total: 0,
        },
        visa: {
          file: null,
          client: {
            id: this.$route.params.id ? this.$route.params.id : null,
            first_name: '',
            last_name: '',
            birthdate: null,
            passport_serial: '',
            passport_number: '',
            contacts: [{
                number: ''
              },
              {
                number: ''
              }
            ],
            given_date: null,
            expire_date: null,
          },
          data: {
            id: null,
            client_id: null,
            actual_price: '',
            market_price: '',
            country_id: null,
            currency_id: null,
            payment_type_id: null,
            is_legal_collection: false,
          }
        },
        clientsOptions: [],
        timeout: null,
        countryOptions: [],
        countryTimeout: null,
        fisrtNameOptions: [],
        firstNameTimeout: null,
        lastNameOptions: [],
        lastNmaeTimeout: null,
        tempFirstname: null,
        tempLastname: null,
        tempCountry: null,
        dataTimeout: null,
        currencies: [],
        selectedCurrency: null,
      }
    },
    created() {
      this.fetchCurrencies()
    },
    mounted() {
      // this.$store.dispatch('settings/fetchCurrencies');
      this.$store.dispatch('settings/fetchMethods');
      this.fetchShowData();

    },
    computed: {
      ...mapGetters('settings', ['paymentType'])
    },
    methods: {
      toBack() {
        this.$router.go(-1)
      },
      handleAdd() {
        const isValid = this.$refs.refFormObserver.validate();
        this.loading = true;
        if (isValid) {
          // disabled button
          this.disabledItem = true;
          setTimeout( () => {
            this.disabledItem = false
          this.loading = false

          }, 1000)
          // client
          if(!this.$route.params.id) {
            this.visa.client.id = this.clientModel.id;
            this.visa.client.contacts = [];
            this.visa.client.contacts.push({number:this.number1},{number:this.number2})
            this.visa.client.contacts = JSON.stringify(this.visa.client.contacts)
            this.visa.client.first_name = this.clientModel.first_name;
            this.visa.client.last_name = this.clientModel.last_name;
            this.visa.client.birthdate = moment(this.clientModel.birthdate).format('YYYY-MM-DD')
          } else {
            this.visa.client = {};
            this.visa.client.id = this.$route.params.id
          }
          // visa data
          this.visa.data.id = this.dataModel.id
          this.visa.data.client_id = this.dataModel.client_id
          this.visa.data.price = this.dataModel.price
          this.visa.data.fee = this.dataModel.fee
          this.visa.data.country_id = this.dataModel.country_id.id ? parseInt(this.dataModel.country_id.id) : this.dataModel.country_id.name_ru  //select
          this.visa.data.currency_id = this.selectedCurrency.id  //select
          this.visa.data.enter_date = this.dataModel.enter_date
          this.visa.data.release_date = this.dataModel.release_date
          this.visa.data.total = this.visa.data.price + this.visa.data.fee
          // file
          this.visa.file = this.file;

          if(this.$route.params.visaId) {
            updateVisa(this.visa.data)
              .then( () => {
                this.loading = false;
                this.toBack();
              })
          } else {
            let fd = new FormData();
            fd.append('client', JSON.stringify(this.visa.client));
            fd.append('visa', JSON.stringify(this.visa.data))
            fd.append('passport_file', this.visa.file)

            storeVisa(fd)
              .then( () => {
                this.loading = false;
                this.toBack();
              })
          }
        }
      },
      fetchShowData() {
        if(this.$route.params.visaId) {
          showVisa(this.$route.params.visaId).then( response => {
            let res = response.data.data;
            this.dataModel.id = res.id;
            this.dataModel.client_id = parseInt(res.client_id)
            this.dataModel.actual_price = parseInt(res.actual_price)
            this.dataModel.market_price = parseInt(res.market_price)
            this.dataModel.country_id = res.country
            this.dataModel.currency_id = res.currency
            this.dataModel.payment_type_id = res.payment_type;
            this.dataModel.is_legal_collection = res.is_legal_collection;
          })
        }
      },
      selectTempCountry(item) {
        if(this.tempCountry) {
          if(this.dataModel.country_id === null) {
            // this.clientModel.first_name = this.tempFirstname
            this.dataModel.country_id = {id:null,name_ru: this.tempCountry}
          }
        }
      },
      countrySelected(item){
        if(typeof item === 'string') {
          this.dataModel.country_id = {id: null, name_ru:item};
        }
        else {
          this.dataModel.country_id = item;
        }
      },
      searchCountry(item) {
        if (item.length > 0) {
          this.tempCountry = item;
          clearTimeout(this.countryTimeout);
          this.countryTimeout = setTimeout(() => {
            getCountries({
              search: item
            }).then(res => {
              this.countryOptions = res.data.data.data
            })
          }, 500)
        }
      },
      fileChange(e) {
        this.file = e.target.files[0]
      },
      toLoware() {
        this.clientModel.passport_serial = this.clientModel.passport_serial.toUpperCase();
        if(this.clientModel.passport_serial.length >= 2) {
          this.$refs.passportNumber.focus()
        }
      },
      changePassort() {
        if(this.clientModel.passport_serial.length > 0 && this.clientModel.passport_number.length > 0) {
          clearTimeout(this.dataTimeout);
          this.dataTimeout = setTimeout(() => {
            getClients({passport_serial: this.clientModel.passport_serial, passport_number: this.clientModel.passport_number}).then( response => {
              let res = response.data.data[0];
              this.clientModel.id = res.id;
              this.clientModel.first_name       = res.first_name;
              this.clientModel.last_name        = res.last_name;
              this.clientModel.birthdate        = res.birthdate;
              this.clientModel.passport_serial  = res.passport_serial;
              this.clientModel.passport_number  = res.passport_number;
              this.clientModel.given_date       = res.given_date;
              this.clientModel.expire_date      = res.expire_date;
              this.clientModel.contacts = JSON.parse(res.contacts);
              if (!this.clientModel.contacts[0]) {
                this.clientModel.contacts = new Array(this.clientModel.contacts)
              }
              if (this.clientModel.contacts.length === 1) {
                this.number1 = this.clientModel.contacts[0].number
              }
              if (this.clientModel.contacts.length === 2) {
                this.number1 = this.clientModel.contacts[0].number
                this.number2 = this.clientModel.contacts[1].number
              }
            })
          }, 400)
        }
      },
      toUpperCaseFirstname() {
        this.clientModel.first_name = this.clientModel.first_name.toUpperCase(); 
      },
      toUpperCaseLastname() {
        this.clientModel.last_name = this.clientModel.last_name.toUpperCase(); 
      },
      fetchCurrencies() {
        getCurrencies().then(res => {
          this.currencies = res.data.data
        })
      }
    },
  }
</script>
